var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('pages.contact.listOfContact'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('common.phone'),"horizontal":""},model:{value:(_vm.searchInfo.phone),callback:function ($$v) {_vm.$set(_vm.searchInfo, "phone", $$v)},expression:"searchInfo.phone"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('common.email'),"horizontal":""},model:{value:(_vm.searchInfo.email),callback:function ($$v) {_vm.$set(_vm.searchInfo, "email", $$v)},expression:"searchInfo.email"}})],1)],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.contactList,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoading,"pagination":"","items-per-page":_vm.itemsPerPage,"items-per-page-select":{
                                label : _vm.$t('common.recordsperpage'),
                                values : [10,20,50,100] }},on:{"pagination-change":_vm.onItemsPerPageChange},scopedSlots:_vm._u([(_vm.contactList != null && _vm.contactList.length > 0)?{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":7}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.totalRecords'))+": ")]),_c('span',{staticStyle:{"padding-left":"5px","color":"crimson","font-weight":"bold"}},[_vm._v(_vm._s(_vm.totalItems))])])])])]},proxy:true}:null,{key:"createdDate_text",fn:function(ref){
                                var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.formatDate(item.createdDate))}})])]}},{key:"user_detail_actions",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDeleteContact(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}},{key:"over-table",fn:function(){return [_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 1),expression:"totalPages > 1"}],attrs:{"activePage":_vm.page,"pages":_vm.totalPages,"size":"sm","align":"center"},on:{"update:activePage":[function($event){_vm.page=$event},_vm.onPageChange],"update:active-page":function($event){_vm.page=$event}}}),_vm._v(" Trang hiện tại: "+_vm._s(_vm.pageIndex + 1)+" Tổng số trang: "+_vm._s(_vm.totalPages)+" ")]},proxy:true},{key:"under-table",fn:function(){return [_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 1),expression:"totalPages > 1"}],attrs:{"activePage":_vm.page,"pages":_vm.totalPages,"size":"sm","align":"center"},on:{"update:activePage":[function($event){_vm.page=$event},_vm.onPageChange],"update:active-page":function($event){_vm.page=$event}}}),_vm._v(" Trang hiện tại: "+_vm._s(_vm.pageIndex + 1)+" Tổng số trang: "+_vm._s(_vm.totalPages)+" ")]},proxy:true}],null,true)})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }