<template>
    <div>
		<CRow>
            <CCol sm="12">
                <ListForm :headerTitle="$t('pages.contact.listOfContact')" :onSearch="onSearch">    
                    <template v-slot:searchBox>
                        <CForm>
                            <CRow>
                                <CCol md="6">
                                    <CInput :label="$t('common.phone')" v-model="searchInfo.phone" horizontal></CInput>
                                </CCol>
                                <CCol md="6">
                                    <CInput :label="$t('common.email')" v-model="searchInfo.email" horizontal></CInput>
                                </CCol>
                            </CRow>
                           
                        </CForm>

                    </template>
                    <template v-slot:list>
                        <CDataTable :items="contactList"
                                    :fields="fields"
                                    :noItemsView="{ noItems: $t('common.nodatafound') }"
                                    hover
                                    striped
                                    border
                                    small
                                    fixed
                                    :loading="isLoading"
                                    pagination
                                    :items-per-page="itemsPerPage"
                                    @pagination-change="onItemsPerPageChange"
                                    :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">
                            <template #footer v-if="contactList != null && contactList.length > 0">
                                <tfoot>
                                    <tr>
                                        <td :colspan="7">
                                            <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ totalItems }}</span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>                          
                            <template #createdDate_text="{item}">
                                <td class="py-2">
                                    <label v-text="formatDate(item.createdDate)" />
                                </td>
                            </template>    
                            <template #user_detail_actions="{item, index}">
                                <td class="py-2">                                    
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onDeleteContact(item, index)">
                                        {{ $t('common.remove') }}
                                    </CButton>                                    
                                </td>
                            </template>

                            <template #over-table>
                                <CPagination :activePage.sync="page"
                                             @update:activePage="onPageChange"
                                             :pages="totalPages"
                                             v-show="totalPages > 1"
                                             size="sm"
                                             align="center" />
                                Trang hiện tại: {{pageIndex + 1}}
                                Tổng số trang: {{totalPages}}
                            </template>

                            <template #under-table>
                                <CPagination :activePage.sync="page"
                                             @update:activePage="onPageChange"
                                             :pages="totalPages"
                                             v-show="totalPages > 1"
                                             size="sm"
                                             align="center" />
                                Trang hiện tại: {{pageIndex + 1}}
                                Tổng số trang: {{totalPages}}
                            </template>
                        </CDataTable>
                    </template>
                </ListForm>
                <Confirmation ref="confirmation"></Confirmation>
            </CCol>
        </CRow>
	</div>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import { mapActions, mapState } from 'vuex'
    
    export default {
        name: 'Contacts',
		data() {            
			return {                
				itemsPerPage: 10,
                warningModal: false,                          
                fields: [
                    { key: 'name', label: i18n.t('common.name') },	
                    { key: 'phone', label: i18n.t('common.phone') },	
                    { key: 'email', label: i18n.t('common.email') }	,                         
                    { key: 'createdDate_text', label: i18n.t('common.createdDate') },
                    { key: 'subject', label: i18n.t('common.subject') },
                    { key: 'content', label: i18n.t('common.content') },      
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
                searchBankName: '',
                searchBankCode: ''
            };
		},
        components: { Confirmation, ListForm },
        computed: {            
            ...mapState('contact', ['searchInfo', 'isLoading', 'contactList', 'totalItems', 'totalPages', 'pageIndex']),    
            page: {
                get() {
                    return this.pageIndex + 1;
                },
                set(newVal) {

                }
            },
        },
        methods: {
            ...mapActions('contact', ['searchContact']),

            formatDate(date) {
                if (date === undefined)
                    return '';
               
                return this.$moment.utc(new Date(date), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');   
            },            
            onSearch() {                
                this.searchContact({ pageIndex: 0, pageSize: this.itemsPerPage });
            },                         
            onItemsPerPageChange(item) {
                this.itemsPerPage = item;
                localStorage.setItem('contactItemsPerPage', item)

                if (this.page > 0) {
                    this.searchContact({ pageIndex: this.page - 1, pageSize: this.itemsPerPage });
                }
            },
            onPageChange(newPageNumber) {                
                this.searchContact({ pageIndex: newPageNumber - 1, pageSize: this.itemsPerPage });
            },       
            onDeleteContact(item) {
                this.$refs.confirmation.show(i18n.t('common.confirmDelete'), async () => {                   
                    var retVal = await this.$store.dispatch("contact/deleteContact", item.id);
                    console.log("==onDeleteContact: ", retVal.data);
                    if (retVal != null && retVal.data) {
                        this.onSearch();
                    }
                });
            },
		},
        created() {
             this.onSearch();
            
            let _iTemPerPage = parseInt(localStorage.getItem('contactItemsPerPage'));
            if (isNaN(_iTemPerPage))
                _iTemPerPage = 10;
            this.itemsPerPage = _iTemPerPage;
        }
	}
</script>